<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group>
            <label
              for="filter_category"
            >
              <strong>
                Category
              </strong>
            </label>
            <b-select
              id="filter_category"
              v-model="filter.category"
              :options="filterCategories"
              :disabled="state.busy || fetching.filter.categories"
              @change="onRefreshTableItemDetails"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group>
            <label
              for="filter_action"
            >
              <strong>
                Action
              </strong>
            </label>
            <b-select
              id="filter_action"
              v-model="filter.action"
              :options="list.filter.actions"
              :disabled="state.busy"
              @change="onRefreshTableItemDetails"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group>
            <label
              for="filter_item"
            >
              <strong>
                Item
              </strong>
            </label>
            <v-select
              id="filter_item"
              v-model="selected.filter.item"
              :options="list.filter.items"
              type="text"
              label="item_label"
              placeholder="search item"
              :disabled="state.busy || fetching.filter.items"
              :loading="fetching.filter.items"
            >
              <template #option="{ item_name, serial_number }">
                <div class="d-flex flex-column">
                  <span>{{ item_name }}</span>
                  <strong>{{ serial_number }}</strong>
                </div>
              </template>
              <template #no-options="">
                no available item
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group>
            <label
              for="filter_search"
            >
              <strong>
                Search
              </strong>
            </label>
            <b-input
              id="filter_search"
              v-model="tableItemDetails.filter"
              type="text"
              placeholder="search here"
              debounce="1000"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-table
        ref="tableItemDetails"
        hover
        responsive
        show-empty
        class="mt-2"
        :per-page="tableItemDetails.perPage"
        :current-page="tableItemDetails.currentPage"
        :items="myProvider"
        :fields="tableItemDetails.fields"
        :sort-by.sync="tableItemDetails.sortBy"
        :sort-desc.sync="tableItemDetails.sortDesc"
        :sort-direction="tableItemDetails.sortDirection"
        :filter="tableItemDetails.filter"
        :filter-included-fields="tableItemDetails.filterOn"
      >
        <template #cell(index)="row">
          {{ tableItemDetails.currentPage * tableItemDetails.perPage - tableItemDetails.perPage + (row.index + 1) }}
        </template>

        <template #cell(actions)="row">
          <div class="text-nowrap">
            <b-button
              v-if="row.item.employee_id"
              size="sm"
              class="mr-1"
              variant="danger"
              @click="onShowItemDetailForm(row.item, 'RETURN')"
            >
              Return
            </b-button>
            <b-button
              v-else
              size="sm"
              class="mr-1"
              variant="success"
              @click="onShowItemDetailForm(row.item, 'ASSIGN')"
            >
              Assign
            </b-button>
            <b-button
              v-if="row.item.employee_id && row.item.issuance_id === null && row.item.is_package === 0"
              size="sm"
              class="text-white mr-1"
              variant="warning"
              @click="onShowItemDetailForm(row.item, 'ISSUE')"
            >
              Issue
            </b-button>
          </div>
        </template>

        <template #cell(item_detail_code)="row">
          <div class="text-nowrap">
            <strong>{{ row.value }}</strong>
          </div>
        </template>

        <template #cell(serial_number)="row">
          <div class="text-nowrap">
            <strong>{{ row.value }}</strong>
          </div>
        </template>

        <template #cell(employee_name)="row">
          <div class="text-nowrap">
            <strong>{{ row.value }}</strong>
          </div>
        </template>

        <template #cell(active)="row">
          {{ row.item.active == 1 ? "Yes" : "No" }}
        </template>

        <template #cell()="row">
          <div class="text-nowrap">
            {{ row.value }}
          </div>
        </template>
      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableItemDetails.perPage"
              :options="tableItemDetails.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableItemDetails.currentPage"
            :total-rows="tableItemDetails.totalRows"
            :per-page="tableItemDetails.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-item-detail"
      size="xl"
      scrollable
      no-close-on-backdrop
      title="Item Detail"
    >
      <ValidationObserver
        ref="formItemDetail"
      >
        <form
          novalidate
          @submit.prevent
        >
          <b-row>

            <b-col
              cols="12"
            >
              <h4>EMPLOYEE</h4>
            </b-col>

            <b-col
              v-if="state.action === 'ASSIGN'"
              cols="12"
            >
              <b-row>
                <b-col
                  cols="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="employee"
                    vid="employee"
                    rules="required"
                  >
                    <b-form-group>
                      <label for="employee">
                        <strong>
                          Code / ID
                        </strong>
                      </label>
                      <v-select
                        id="employee"
                        v-model="selected.itemDetail.employee"
                        :options="list.itemDetail.employees"
                        type="text"
                        label="employee_code"
                        placeholder="search employee here"
                        :class="[errors.length > 0 ? 'is-invalid' : null]"
                        :disabled="state.busy || fetching.itemDetail.employees"
                        :loading="fetching.itemDetail.employees"
                      >
                        <template #option="{ employee_name, employee_code }">
                          <div class="d-flex flex-column">
                            <strong>{{ employee_name }}</strong>
                            <span>{{ employee_code }}</span>
                          </div>
                        </template>
                        <template #no-options="">
                          no available employee
                        </template>
                      </v-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col
                  cols="8"
                >
                  <b-form-group>
                    <label
                      for="employee_name"
                    >
                      <strong>
                        Name
                      </strong>
                    </label>
                    <b-input
                      id="employee_name"
                      v-model="itemDetail.employee_name"
                      type="text"
                      placeholder="no assigned employee"
                      autocomplete="off"
                      disabled
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>

            <b-col
              v-else
              cols="12"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group>
                    <label
                      for="employee_code"
                    >
                      <strong>
                        Code / ID
                      </strong>
                    </label>
                    <b-input
                      id="employee_code"
                      v-model="itemDetail.employee_code"
                      type="text"
                      placeholder="no assigned employee"
                      autocomplete="off"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="8"
                >
                  <b-form-group>
                    <label
                      for="employee_name"
                    >
                      <strong>
                        Name
                      </strong>
                    </label>
                    <b-input
                      id="employee_name"
                      v-model="itemDetail.employee_name"
                      type="text"
                      placeholder="no assigned employee"
                      autocomplete="off"
                      disabled
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>

            <b-col cols="12 d-none d-md-block">
              <hr>
            </b-col>

            <b-col
              cols="12"
            >
              <h4>ITEM</h4>
            </b-col>

            <b-col
              cols="12"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group>
                    <label
                      for="item_serial_number"
                    >
                      <strong>
                        Serial Number
                      </strong>
                    </label>
                    <b-input
                      id="item_serial_number"
                      v-model="itemDetail.item_serial_number"
                      type="text"
                      placeholder="no assigned item"
                      autocomplete="off"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="8"
                >
                  <b-form-group>
                    <label
                      for="item_name"
                    >
                      <strong>
                        Name
                      </strong>
                    </label>
                    <b-input
                      id="item_name"
                      v-model="itemDetail.item_name"
                      type="text"
                      placeholder="no assigned item"
                      autocomplete="off"
                      disabled
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>

            <b-col cols="12 d-none d-md-block">
              <hr>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group>
                <label
                  for="item_detail_code"
                >
                  <strong>
                    Item Detail Code
                  </strong>
                </label>
                <b-input
                  id="item_detail_code"
                  v-model="itemDetail.item_detail_code"
                  type="text"
                  autocomplete="off"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group>
                <label
                  for="type_name"
                >
                  <strong>
                    Type
                  </strong>
                </label>
                <b-input
                  id="type_name"
                  v-model="itemDetail.type_name"
                  type="text"
                  autocomplete="off"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group>
                <label
                  for="category_name"
                >
                  <strong>
                    Category
                  </strong>
                </label>
                <b-input
                  id="category_name"
                  v-model="itemDetail.category_name"
                  type="text"
                  autocomplete="off"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group>
                <label
                  for="serial_number"
                >
                  <strong>
                    Serial Number
                  </strong>
                </label>
                <b-input
                  id="serial_number"
                  v-model="itemDetail.serial_number"
                  type="text"
                  autocomplete="off"
                  placeholder="no serial number"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="8"
            >
              <b-form-group>
                <label
                  for="item_detail_name"
                >
                  <strong>
                    Name
                  </strong>
                </label>
                <b-input
                  id="item_detail_name"
                  v-model="itemDetail.item_detail_name"
                  type="text"
                  autocomplete="off"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col cols="12 d-none d-md-block">
              <hr>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group>
                <label
                  for="original_cost"
                >
                  <strong>
                    Original Cost
                  </strong>
                </label>
                <b-input
                  id="original_cost"
                  v-model="itemDetail.original_cost"
                  type="text"
                  autocomplete="off"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <ValidationProvider
                #default="{ errors }"
                name="status"
                vid="status"
                rules="required"
              >
                <b-form-group>
                  <label for="status">
                    <strong>
                      Status
                    </strong>
                  </label>
                  <b-form-select
                    id="status"
                    v-model="itemDetail.status"
                    :options="list.itemDetail.statuses"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy || fetching.itemDetail.statuses"
                  >
                    <template #first>
                      <b-form-select-option
                        :value="null"
                        disabled
                      >
                        -- select status --
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              v-if="itemDetail.costable"
              cols="12"
              md="4"
            >
              <ValidationProvider
                #default="{ errors }"
                name="status cost"
                vid="status_cost"
                rules="required"
              >
                <b-form-group>
                  <label for="status_cost">
                    <strong>
                      Cost
                    </strong>
                  </label>
                  <b-input
                    id="status_cost"
                    v-model="itemDetail.status_cost"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="enter cost"
                    autocomplete="off"
                    step="0.01"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="remarks"
                vid="remarks"
                rules="required|max:500"
              >
                <b-form-group>
                  <label for="remarks">
                    <strong>
                      Remarks
                    </strong>
                  </label>
                  <b-textarea
                    id="remarks"
                    v-model="itemDetail.remarks"
                    rows="6"
                    max-rows="12"
                    placeholder="enter remarks"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

          </b-row>
        </form>
      </ValidationObserver>

      <template #modal-footer="{ cancel }">
        <div class="col-12 d-flex justify-content-between">
          <b-button
            v-if="state.action === 'ISSUE'"
            variant="success"
            :disabled="state.busy"
            @click="onValidateIssueItemDetail"
          >
            Issue
          </b-button>
          <b-button
            v-if="state.action === 'RETURN'"
            variant="success"
            :disabled="state.busy"
            @click="onValidateReturnItemDetail"
          >
            Return
          </b-button>
          <b-button
            v-if="state.action === 'ASSIGN'"
            variant="success"
            :disabled="state.busy"
            @click="onValidateAssignItemDetail"
          >
            Assign
          </b-button>
          <b-button
            variant="danger"
            :disabled="state.busy"
            @click="cancel()"
          >
            Close Window
          </b-button>
        </div>
      </template>

    </b-modal>

  </b-container>
</template>
<script>
import _ from 'lodash'
import { core } from '@/config/pluginInit'
import {
  AxiosError,
  AdminItemDetailService,
  SharedListService
} from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'AdminAssignReturnItemDetails',

  middleware: ['auth', 'admin'],

  metaInfo () {
    return {
      title: 'Assign or Return Item Details'
    }
  },

  mixins: [formatter],

  data () {
    return {
      state: {
        busy: false,
        assign: false
      },
      fetching: {
        filter: {
          items: false,
          categories: false
        },
        itemDetail: {
          items: false,
          types: false,
          statuses: false,
          employees: false
        }
      },
      filter: {
        item: 'All',
        category: 'All',
        action: 'All'
      },
      list: {
        filter: {
          categories: [],
          items: [],
          actions: [
            {
              value: 'All', text: 'All'
            },
            {
              value: 1, text: 'Assignable'
            },
            {
              value: 0, text: 'Returnable'
            }
          ]
        },
        itemDetail: {
          items: [],
          types: [],
          statuses: [],
          employees: []
        }
      },
      selected: {
        filter: {
          item: null
        },
        itemDetail: {
          employee: null
        }
      },
      itemDetail: {
        id: null,
        type: null,
        type_name: null,
        category: null,
        category_name: null,
        supplier: null,
        item: null,
        item_name: null,
        item_serial_number: null,
        employee: null,
        employee_code: null,
        employee_name: null,
        item_detail_code: null,
        item_detail_name: null,
        serial_number: null,
        purchase_date: null,
        brand: null,
        original_cost: 0.00,
        specifications: null,
        active: 0,
        status: null,
        status_cost: 0.00,
        costable: false,
        remarks: null
      },
      tableItemDetails: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        fields: [
          { key: 'index', class: 'text-center' },
          { key: 'actions' },
          { key: 'item.serial_number', label: 'parent serial number' },
          { key: 'item_detail_code', class: 'text-center' },
          { key: 'category_name', label: 'category' },
          { key: 'serial_number' },
          { key: 'item_detail_name', label: 'peripheral' },
          { key: 'status_name', label: 'status', class: 'text-center' },
          { key: 'employee_name', label: 'employee' },
          { key: 'active', class: 'text-center' },
          { key: 'updated_at', formatter: this.dateTimeShortFormatter, sortable: true }
        ]
      }
    }
  },

  computed: {

    modalItemTitle () {
      return this.state.editing ? 'Edit Item Detail' : 'Add Item Detail'
    },

    filterCategories () {
      return [{ text: 'All', value: 'All' }].concat(this.list.filter.categories)
    }

  },

  watch: {

    'selected.filter.item' (item) {
      if (item) {
        this.filter.item = item.id
      } else {
        this.filter.item = 'All'
      }

      this.onRefreshTableItemDetails()
    },

    'selected.itemDetail.employee' (employee) {
      this.itemDetail.employee = employee?.id || null
      this.itemDetail.employee_code = employee?.employee_code || null
      this.itemDetail.employee_name = employee?.employee_name || null
    },

    'itemDetail.status' (itemDetail) {
      const status = this.list.itemDetail.statuses.find(status => status.value === itemDetail)
      if (status) {
        this.itemDetail.costable = status.costable
      } else {
        this.itemDetail.costable = false
      }
    }
  },

  mounted () {
    core.index()
    this.getFilterCategories().then(() => {
      this.getFilterItems()
    })
  },

  methods: {

    async myProvider (ctx) {
      return await AdminItemDetailService.get(
        this.objectToUrl({
          page: ctx.currentPage,
          per_page: ctx.perPage,
          sort: ctx.sortBy,
          sort_desc: ctx.sortDesc,
          filter_text: ctx.filter,
          filter_item: this.filter.item,
          filter_category: this.filter.category,
          filter_employee_action: this.filter.action
        })
      ).then(({ data }) => {
        this.tableItemDetails.totalRows = data.total_rows
        return data.items
      }).catch(() => {
        return []
      })
    },

    async getFilterCategories () {
      this.fetching.filter.categories = this.state.busy = true
      return new Promise(resolve => {
        SharedListService.getCategories('parent=0').then(({ data }) => {
          this.list.filter.categories = data.map(({ id, category_name }) => ({ value: id, text: category_name }))
          this.fetching.filter.categories = this.state.busy = false
          resolve(true)
        })
      })
    },

    async getFilterItems () {
      this.selected.filter.item = null
      this.fetching.filter.items = this.state.busy = true
      return new Promise(resolve => {
        SharedListService.getItems().then(({ data }) => {
          this.list.filter.items = data.map(
            item => ({
              id: item.id,
              category_name: item.category_name,
              serial_number: item.serial_number,
              item_name: item.item_name,
              item_label: `${item.item_name} ( ${item.serial_number} )`
            })
          )
          this.fetching.filter.items = this.state.busy = false
          resolve(true)
        })
      })
    },

    async getEmployees () {
      this.fetching.itemDetail.employees = this.state.busy = true
      return new Promise(resolve => {
        SharedListService.getEmployees().then(({ data }) => {
          this.list.itemDetail.employees = data.map(
            employee => ({
              id: employee.id,
              employee_code: employee.employee_code,
              employee_name: employee.employee_name,
              employee_label: `${employee.employee_name} ( ${employee.employee_code} )`
            })
          )
          this.fetching.itemDetail.employees = this.state.busy = false
          resolve(true)
        })
      })
    },

    async getItemStatuses () {
      this.fetching.itemDetail.statuses = this.state.busy = true
      return new Promise(resolve => {
        SharedListService.getStatuses().then(({ data }) => {
          this.list.itemDetail.statuses = data.map(({ id, status_name, costable }) => ({
            text: status_name,
            value: id,
            costable: costable
          }))
          this.fetching.itemDetail.statuses = this.state.busy = false
          resolve(true)
        })
      })
    },

    onRefreshTableItemDetails () {
      this.$refs.tableItemDetails.refresh()
    },

    onShowItemDetailForm (itemDetail, action = 'ASSIGN') {
      this.state.action = action

      this.itemDetail.id = itemDetail.id
      this.itemDetail.item_detail_code = itemDetail.item_detail_code
      this.itemDetail.item_detail_name = itemDetail.item_detail_name
      this.itemDetail.serial_number = itemDetail.serial_number
      this.itemDetail.original_cost = itemDetail.original_cost
      this.itemDetail.remarks = null

      this.itemDetail.item = null
      this.itemDetail.item_name = null
      this.itemDetail.item_serial_number = null

      if (itemDetail.item) {
        this.itemDetail.item = itemDetail.item.id
        this.itemDetail.item_name = itemDetail.item.item_name
        this.itemDetail.item_serial_number = itemDetail.item.serial_number
      }

      if (itemDetail.employee) {
        this.selected.itemDetail.employee = {
          id: itemDetail.id,
          employee_code: itemDetail.employee.employee_code,
          employee_name: itemDetail.employee_name,
          employee_label: `${itemDetail.employee_name} ( ${itemDetail.employee.employee_code} )`
        }

        this.itemDetail.employee_code = itemDetail.employee.employee_code
      } else {
        if (action === 'ASSIGN') {
          this.selected.itemDetail.employee = null
          this.itemDetail.employee = null
          this.itemDetail.employee_name = null
        } else {
          this.itemDetail.employee = itemDetail.employee_id
          this.itemDetail.employee_name = itemDetail.employee_name
        }

        this.itemDetail.employee_code = null
      }

      this.itemDetail.type = itemDetail.type_id
      this.itemDetail.type_name = itemDetail.type_name
      this.itemDetail.category = itemDetail.category_id
      this.itemDetail.category_name = itemDetail.category_name

      this.getEmployees().then(() => {
        this.getItemStatuses().then(() => {
          this.itemDetail.status = itemDetail.status_id
          this.itemDetail.status_cost = itemDetail.status_cost
          this.$bvModal.show('modal-item-detail')
        })
      })
    },

    onValidateAssignItemDetail () {
      this.onValidateFormItemDetail(
        'Do you really want to assign this item details?',
        'ASSIGN'
      )
    },

    onValidateIssueItemDetail () {
      this.onValidateFormItemDetail(
        'Do you really want to assign this item details?',
        'ISSUE'
      )
    },

    onValidateReturnItemDetail () {
      this.onValidateFormItemDetail(
        'Do you really want to assign this item details?',
        'RETURN'
      )
    },

    async onValidateFormItemDetail (text, action) {
      await this.$refs.formItemDetail.validate().then(async success => {
        if (success) {
          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: text,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              this.state.busy = true
              switch (this.state.action) {
                case 'ISSUE': {
                  return this.onIssueItemDetails()
                }
                case 'RETURN': {
                  return this.onReturnItemDetails()
                }
                default: {
                  return this.onAssignItemDetails()
                }
              }
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk(
            'Oops! There were problem with your inputs.', {
              title: 'Validation Error',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'danger',
              centered: true
            }
          )
        }
      })
    },

    async onAssignItemDetails () {
      return new Promise(resolve => {
        AdminItemDetailService.assign(this.itemDetail).then(({ data }) => {
          this.$bvModal.hide('modal-item-detail')
          this.state.busy = false
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            const row = _.find(this.$refs.tableItemDetails.localItems, { id: data.itemDetail.id })
            row.employee = data.itemDetail.employee
            row.employee_id = data.itemDetail.employee_id
            row.employee_name = data.itemDetail.employee_name
            row.status = data.itemDetail.status
            row.status_id = data.itemDetail.status_id
            row.status_name = data.itemDetail.status_name
            row.status_cost = data.itemDetail.status_cost
            row.remarks = data.itemDetail.remarks
            row.updated_at = data.itemDetail.updated_at
            row.issuance_id = data.itemDetail.issuance_id
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.formItemDetail.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    },

    async onIssueItemDetails () {
      return new Promise(resolve => {
        AdminItemDetailService.issue(this.itemDetail).then(({ data }) => {
          this.$bvModal.hide('modal-item-detail')
          this.state.busy = false
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            const row = _.find(this.$refs.tableItemDetails.localItems, { id: data.itemDetail.id })
            row.employee = data.itemDetail.employee
            row.employee_id = data.itemDetail.employee_id
            row.employee_name = data.itemDetail.employee_name
            row.status = data.itemDetail.status
            row.status_id = data.itemDetail.status_id
            row.status_name = data.itemDetail.status_name
            row.status_cost = data.itemDetail.status_cost
            row.remarks = data.itemDetail.remarks
            row.updated_at = data.itemDetail.updated_at
            row.issuance_id = data.itemDetail.issuance_id
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.formItemDetail.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    },

    async onReturnItemDetails () {
      return new Promise(resolve => {
        AdminItemDetailService.return(this.itemDetail).then(({ data }) => {
          this.$bvModal.hide('modal-item-detail')
          this.state.busy = false
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            const row = _.find(this.$refs.tableItemDetails.localItems, { id: data.itemDetail.id })
            row.employee = data.itemDetail.employee
            row.employee_id = data.itemDetail.employee_id
            row.employee_name = data.itemDetail.employee_name
            row.status = data.itemDetail.status
            row.status_id = data.itemDetail.status_id
            row.status_name = data.itemDetail.status_name
            row.status_cost = data.itemDetail.status_cost
            row.remarks = data.itemDetail.remarks
            row.updated_at = data.itemDetail.updated_at
            row.issuance_id = data.itemDetail.issuance_id
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.formItemDetail.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    }

  }
}
</script>
